<template>
  <div class="form-panel">
    <el-row v-for="item in listData" :key="item.id">
      <el-col :span="24">
        <div class="form-row">
          <div class="form-label">{{ item.attach_Item_Name }}：</div>
          <div class="form-text">
            <a
              :href="item.file_Path"
              :download="item.file_Name"
              target="_blank"
              >{{ item.file_Name }}</a
            >
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";

export default defineComponent({
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const listData: Array<any> = reactive(props.data as any);
    return {
      listData,
    };
  },
});
</script>

<style scoped>
</style>