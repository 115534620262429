
import { defineComponent, reactive, toRefs } from "vue";

export default defineComponent({
  props: ["visible"],
  emits: ["save"],
  setup() {
    const review = reactive({ result: null, memo: "" });

    return {
      review,
    };
  },
});
