
import { parseTime } from "@/utils/index.js";
import { ElMessage } from "element-plus";
import { useAmountReview } from "@/network/projectFundApply.ts";
import { defineComponent, toRefs, ref, reactive, watch, onMounted } from "vue";
import settings from "@/settings";
export default defineComponent({
  props: {
    setting: { type: Object },
  },
  setup(props, { emit }) {
    const { visible, id } = toRefs(props.setting as any);
    const [isLoading, form, find] = useAmountReview(id);
    // watch(visible, (v) => {
    //   if (v) {
    //     find().then(() => {
    //       //console.log(form);
    //     });
    //   }
    // });

    onMounted(() => {
      find().then(() => {
        //console.log(form);
      });
    })
    return {
      parseTime,
      isLoading,
      visible,
      form,
    };
  },
});
