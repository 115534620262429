<template>
  <el-dialog
    :title="title"
    v-model="visible"
    @close="close"
    width="80%"
    :fullscreen="true"
  >
    <DocContent :setting="docShowSetting"  v-if="docShowSetting.visible"></DocContent>

    <el-skeleton :loading="loading" animated :rows="10">
      <template #default>
        <el-tabs v-model="activeName" type="border-card" class="form-panel">
          <el-tab-pane label="项目概况" name="first">
            <el-collapse v-model="firstCollapseActiveName">
              <el-collapse-item title="项目概况" name="1">
                <el-row>
                  <el-col :span="12">
                    <div class="form-row">
                      <div class="form-label">计划书批文：</div>
                      <div class="form-text">
                        <DocViewBtn
                          :doc="setupDoc"
                          @show="showDocContent(form.setupDoc_Id)"
                        ></DocViewBtn>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="form-row">
                      <div class="form-label">方案批文：</div>
                      <div class="form-text">
                        <span v-if="!form.needProposalDoc">
                          {{ form.noDoc_Note }}
                        </span>
                        <DocViewBtn
                          :doc="proposalDoc"
                          @show="showDocContent(form.proposalDoc_Id)"
                        ></DocViewBtn>
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12"></el-col>
                  <el-col :span="12">
                    <div class="form-row">
                      <div class="form-label">方案设计单位：</div>
                      <div class="form-text">
                        {{ form.planDesignUnit }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <div class="form-row">
                      <div class="form-label">项目单位名称：</div>
                      <div class="form-text">
                        {{ form.heritageUnit_Name }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="form-row">
                      <div class="form-label">项目状态：</div>
                      <div class="form-text">
                        {{ form.status_Name }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <div class="form-row">
                      <div class="form-label">项目类型：</div>
                      <div class="form-text">
                        {{ form.project_Classify_Name }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="form-row">
                      <div class="form-label">项目子类型：</div>
                      <div class="form-text">
                        {{ form.project_SubClassify_Name }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <div class="form-row">
                      <div class="form-label">项目总概算(万元)：</div>
                      <div class="form-text">
                        {{ form.budget_Total }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="form-row">
                      <div class="form-label">申请补助资金(万元)：</div>
                      <div class="form-text">
                        {{
                          form.province_Apply_Count +
                          form.country_Apply_Count +
                          form.other_Apply_Count
                        }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="6">
                    <div class="form-row">
                      <div class="form-label">申请省级经费(万元)：</div>
                      <div class="form-text">
                        {{ form.province_Apply_Count }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="form-row">
                      <div class="form-label">申请国家经费(万元)：</div>
                      <div class="form-text">
                        {{ form.country_Apply_Count }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="form-row">
                      <div class="form-label">申请其他经费(万元)：</div>
                      <div class="form-text">
                        {{ form.other_Apply_Count }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <div class="form-row">
                      <div class="form-label">预算控制数(万元)：</div>
                      <div class="form-text">
                        {{ form.assessment_Money }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="form-row">
                      <div class="form-label">累计安排资金(万元)：</div>
                      <div class="form-text">
                        {{ form.totalGive_Money }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </el-collapse-item>
              <el-collapse-item title="评审记录" name="2">
                <ReviewLogTable
                  :data="form.amountReviewFormList"
                  :project="form"
                ></ReviewLogTable>
                <!-- <h4>评审报告：</h4> -->
              </el-collapse-item>
              <el-collapse-item title="绩效目标填报记录" name="3">
                <PerformanceTable
                  :data="form.performanceScoreList"
                  :project="form"
                ></PerformanceTable
              ></el-collapse-item>
              <el-collapse-item title="资金申请记录" name="4">
                <BudgetApplyTable :data="form.fundApplyList"></BudgetApplyTable
              ></el-collapse-item>
              <!-- <el-collapse-item title="年报及结项记录" name="5">
                <YearReportTable></YearReportTable
              ></el-collapse-item>
              <el-collapse-item title="调整预算记录" name="6">
                <BudgetAdjustLogTable></BudgetAdjustLogTable
              ></el-collapse-item> -->
            </el-collapse>
          </el-tab-pane>
          <el-tab-pane label="资金申请书">
            <div style="text-align: right; margin-bottom: 15px">
              <el-button
                type="primary"
                @click="exportFundProjectWord(id)"
                icon="el-icon-download"
                >导出Word</el-button
              >
            </div>
            <el-collapse v-model="secondCollapseActiveName">
              <el-collapse-item title="基本信息" name="1">
                <!-- <el-row>
                  <el-col :span="24">
                    <div class="form-row">
                      <div class="form-label">项目年度：</div>
                      <div class="form-text">
                        {{ form.project_Year }}
                      </div>
                    </div>
                  </el-col>
                </el-row> -->
                <el-row>
                  <el-col :span="12">
                    <div class="form-row">
                      <div class="form-label">资金申请单位：</div>
                      <div class="form-text">
                        {{ form.execute_Unit_Name }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="form-row">
                      <div class="form-label">资金管理单位：</div>
                      <div class="form-text">
                        {{ form.fund_Manage_Unit }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <div class="form-row">
                      <div class="form-label">项目实施单位：</div>
                      <div class="form-text">
                        {{ form.implement_Unit }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="form-row">
                      <div class="form-label">法人代表：</div>
                      <div class="form-text">
                        {{ form.project_Compile_Unit_Name }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <div class="form-row">
                      <div class="form-label">项目负责人：</div>
                      <div class="form-text">
                        {{ form.project_Manager }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="form-row">
                      <div class="form-label">联系电话：</div>
                      <div class="form-text">
                        {{ form.manager_Cell_Phone }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <div class="form-row">
                      <div class="form-label">项目联系人：</div>
                      <div class="form-text">
                        {{ form.superior_Name }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="form-row">
                      <div class="form-label">联系电话：</div>
                      <div class="form-text">
                        {{ form.superior_Contact }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <div class="form-row">
                      <div class="form-label">预算编制单位：</div>
                      <div class="form-text">
                        {{ form.compile_Unit_Name }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="form-row">
                      <div class="form-label">通讯地址：</div>
                      <div class="form-text">
                        {{ form.unit_Address }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <div class="form-row">
                  <div class="form-label">管理单位简介：</div>
                  <div class="form-text">
                    {{ form.unit_Brief_Introduce }}
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-label">保护单位基本情况：</div>
                  <div class="form-text">
                    {{ form.brief_Description }}
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-label">历年保护维修情况：</div>
                  <div class="form-text">
                    {{ form.past_Protect_Description }}
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-label">项目拟实施内容简要介绍：</div>
                  <div class="form-text">
                    {{ form.project_Content }}
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-label">分年度工作计划：</div>
                  <div class="form-text">
                    {{ form.annual_Execute_Plan }}
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-label">备注：</div>
                  <div class="form-text">
                    {{ form.comments }}
                  </div>
                </div>
              </el-collapse-item>
              <el-collapse-item title="资金计划" name="2">
                <AnnualBudgetTable
                  :edit="false"
                  :data="form.annual_BudgetList"
                ></AnnualBudgetTable>
              </el-collapse-item>
              <el-collapse-item title="预算构成表" name="3">
                <BudgetContentTable
                  :edit="false"
                  :data="form.budget_ContentList"
                ></BudgetContentTable>
              </el-collapse-item>
            </el-collapse>
          </el-tab-pane>
          <el-tab-pane label="附件">
            <el-collapse v-model="thirdCollapseActiveName">
              <el-collapse-item title="项目附件" name="1">
                <ProjectAttachList
                  :data="form.projectAttachList"
                ></ProjectAttachList>
              </el-collapse-item>
              <el-collapse-item title="请示文件" name="2">
                <RequestDocList :data="form.requestDocList"></RequestDocList>
              </el-collapse-item>
            </el-collapse>
          </el-tab-pane>
          <el-tab-pane label="操作历史">
            <LogHistory :data="actionHistory"></LogHistory>
          </el-tab-pane>
        </el-tabs>

        <ReViewPanel :visible="review" @save="saveReview" v-if="review"></ReViewPanel>
      </template>
    </el-skeleton>
  </el-dialog>
</template>

<script lang="ts">
import LogHistory from "./logHistory.vue";
import AnnualBudgetTable from "./annual_budgetTable.vue";
import BudgetContentTable from "./budget_contentTable.vue";
import ReviewLogTable from "./reviewLogTable";
import PerformanceTable from "./performanceTable";
import BudgetApplyTable from "./budgetApplyTable";

// import BudgetAdjustLogTable from "./budgetAdjustLogTable";
// import YearReportTable from "./yearReportTable";

import { reactiveShowSetting } from "@/types/showSetting.ts";
import { DocComponents } from "@/network/officialDoc.ts";
import {
  useFindProjectFundApplyView,
  useProjectFundApplyToDocs,
  useQueryActionHistory,
  useRunProjectFundApply,
  exportFundProjectWord,
} from "@/network/projectFundApply.ts";
import {
  Ref,
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRef,
  toRefs,
  watch,
  PropType,
  onUpdated,
} from "vue";
import ReViewPanel from "@/components/reviewPanel.vue";
import ProjectAttachList from "@/views/shared/projectAttachList.vue";
import RequestDocList from "@/views/shared/requestDocList.vue";

//components
export default defineComponent({
  name: "projectCreate",
  components: {
    LogHistory,
    ProjectAttachList,
    RequestDocList,
    ReViewPanel,
    ...DocComponents,
    ReviewLogTable,
    PerformanceTable,
    BudgetApplyTable,
    // YearReportTable,
    // BudgetAdjustLogTable,
    AnnualBudgetTable,
    BudgetContentTable,
  },
  props: {
    setting: {
      type: Object as PropType<{
        id: number;
        visible: boolean;
        review: boolean;
      }>,
    },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const activeName = ref(null);
    const firstCollapseActiveName = ref(null);
    const secondCollapseActiveName = ref(null);
    const thirdCollapseActiveName = ref(null);

    const { id, visible, review } = toRefs(props.setting as any);
    const [actionHistory, queryHistory] = useQueryActionHistory(id);
    const [loading, form, find, cancel]: [Ref<boolean>, any, any, any] =
      useFindProjectFundApplyView(id);
    const [setupDoc, proposalDoc] = useProjectFundApplyToDocs(form);

    const close = () => {
      if (cancel.value != null && loading.value) {
        cancel.value.cancel("取消加载...");
      }
    };

    const reload = () => {
      if (id.value == 0) return;
      queryHistory();
      find();
      activeName.value = "first";
      firstCollapseActiveName.value = ["1", "2", "3", "4", "5", "6"];
      secondCollapseActiveName.value = ["1", "2", "3"];
      thirdCollapseActiveName.value = ["1", "2"];
    };
    //reload();
    // watch(visible, () => {
    //   if (visible) {
    //     reload();
    //   }
    // });

    onMounted(()=>{
      reload();
    })
    const docShowSetting = reactive({
      id: null,
      visible: false,
      approve: false,
    });

    const showDocContent = (id) => {
      docShowSetting.id = id;
      docShowSetting.visible = true;
    };

    const [isProcessing, run] = useRunProjectFundApply(() => {
      visible.value = false;
      emit("change");
      //console.log("111");
    });

    const saveReview = (result) => {
      run(id.value, Object.assign({ forceFlag: 4 }, result));
    };
    const title = computed(() => {
      if (loading.value) {
        return "数据加载中...";
      } else {
        return `[${form.value.project_Code}]` + form.value.project_Name;
      }
    });

    return {
      id,
      isProcessing,
      showDocContent,
      actionHistory,
      review,
      saveReview,
      close,
      firstCollapseActiveName,
      secondCollapseActiveName,
      thirdCollapseActiveName,
      activeName,
      visible,
      loading,
      docShowSetting,
      setupDoc,
      proposalDoc,
      title,
      form,
      exportFundProjectWord,
    };
  },
});
</script>
