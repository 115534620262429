<template>
  <el-dialog v-model="visible" title="查看评审单">
    <el-card v-loading="isLoading">
      <h1 style="text-align: center">文物保护资金预算评审表</h1>
      <p style="
          margin-bottom: 10px;
          text-align: right;
          border-bottom: 1px dashed black;
          padding-bottom: 10px;
        ">
        项目编号：{{ setting.project_Code }}
      </p>
      <div class="borderForm">
        <el-form :model="form" label-width="200px" ref="refForm">
          <el-form-item label="项目名称">
            {{ setting.project_Name }}
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="送审金额（万元）">
                {{ form.submit_Money }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="评审金额（万元）">
                {{ form.assessment_Money }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <el-table :data="form.reduces" border show-summary>
              <el-table-column label="核减项目、理由和金额">
                <el-table-column label="核减项目名称">
                  <template #default="{ row: item }">
                    {{ item.name }}
                  </template>
                </el-table-column>
                <el-table-column label="核减理由">
                  <template #default="{ row: item }">
                    {{ item.reason }}
                  </template>
                </el-table-column>
                <el-table-column label="核减金额（万元）" prop="reduceMoney" sortable>
                  <template #default="{ row: item }">
                    {{ item.reduceMoney }}
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table>
          </el-form-item>

          <el-form-item label="其他说明及建议">
            {{ form.otherAdvise }}
          </el-form-item>

          <el-form-item label="审定专家意见">
            {{ form.expertAdvise }}</el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="审定专家签字">
                {{ form.expertSignIn }}
              </el-form-item></el-col>
            <el-col :span="12">
              <el-form-item label="审定日期">{{
                parseTime(form.expertSignInDate, "{yyyy}-{mm}-{dd}")
              }}</el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="审核专家签字">{{
                form.checkSignIn
              }}</el-form-item></el-col>
            <el-col :span="12">
              <el-form-item label="审核日期">{{
                parseTime(form.checkDate, "{yyyy}-{mm}-{dd}")
              }}</el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="附件">
            <el-link v-if="form.attachment" :href="form.attachment.file_Path" target="_blank" type="primary">{{
              form.attachment.file_Name }}</el-link>
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visible = false">关闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import { parseTime } from "@/utils/index.js";
import { ElMessage } from "element-plus";
import { useAmountReview } from "@/network/projectFundApply.ts";
import { defineComponent, toRefs, ref, reactive, watch, onMounted } from "vue";
import settings from "@/settings";
export default defineComponent({
  props: {
    setting: { type: Object },
  },
  setup(props, { emit }) {
    const { visible, id } = toRefs(props.setting as any);
    const [isLoading, form, find] = useAmountReview(id);
    // watch(visible, (v) => {
    //   if (v) {
    //     find().then(() => {
    //       //console.log(form);
    //     });
    //   }
    // });

    onMounted(() => {
      find().then(() => {
        //console.log(form);
      });
    })
    return {
      parseTime,
      isLoading,
      visible,
      form,
    };
  },
});
</script>

<style scoped></style>