
import { defineComponent, reactive } from "vue";

export default defineComponent({
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const listData: Array<any> = reactive(props.data as any);
    return {
      listData,
    };
  },
});
