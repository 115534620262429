<template>
  <el-card
    class="review_card"
    v-drag="{ triggerEl: '.el-card__header' }"
    v-show="visible"
  >
    <template #header>
      <div class="title"><i class="el-icon-s-check"></i> 审核</div>
    </template>
    <el-form :model="review">
      <el-form-item label="审核结果">
        <el-radio-group v-model="review.result">
          <el-radio-button :label="true">通过</el-radio-button>
          <el-radio-button :label="false">不通过</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="审核意见">
        <el-input type="textarea" :rows="4" v-model="review.memo"></el-input>
      </el-form-item>
      <el-form-item>
        <div style="text-align:center">
          <el-button type="primary" @click="$emit('save', review)"
            >提交</el-button
          >
        </div>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";

export default defineComponent({
  props: ["visible"],
  emits: ["save"],
  setup() {
    const review = reactive({ result: null, memo: "" });

    return {
      review,
    };
  },
});
</script>

<style scoped>
</style>