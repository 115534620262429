<template>
  <el-dialog v-model="visible" title="绩效目标" width="70%">
    <el-tabs type="border-card" v-loading="isLoading" v-model="activeName">
      <el-tab-pane label="绩效详情" name="first">
        <h1 style="text-align: center">文物保护资金项目绩效目标申报表</h1>
        <el-form :model="form" label-width="200px" id="performanceForm" ref="refForm">
          <el-form-item label="项目名称">
            {{ setting.project_Name }}
          </el-form-item>
          <el-form-item label="所属专项">
            {{ form.useItem }}
            <!--山东省重点文物保护专项补助资金-->
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="省级主管部门">
                {{ form.provDept }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="具体实施单位">
                {{ form.doDept }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="资金情况(万元)">
            <el-form-item label="年度资金总额">
              {{ form.yearAmount }}</el-form-item>
            <el-form-item label="其中：财政资金">
              {{ form.fiscalAmount }}</el-form-item>
            <el-form-item label="其他资金">
              {{ form.otherAmount }}</el-form-item>
          </el-form-item>
          <el-form-item label="总体目标">
            {{ form.aim }}
          </el-form-item>
          <el-form-item label="绩效指标">
            <el-table :data="form.performanceScoreItems" style="margin: 5px; width: 100%">
              <el-table-column label="一级指标" width="120px">
                <template #default="{ row: item }">
                  {{ item.first }}
                </template>
              </el-table-column>
              <el-table-column label="二级指标" width="170px">
                <template #default="{ row: item }">
                  {{ item.second }}
                </template>
              </el-table-column>
              <el-table-column label="三级指标">
                <template #default="{ row: item }">
                  {{ item.third }}
                </template>
              </el-table-column>
              <el-table-column label="指标值">
                <template #default="{ row: item }">
                  {{ item.value }}
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <!-- <el-form-item label="其他需要说明的事项">
          <el-input type="textarea" rows="5"></el-input>
        </el-form-item> -->
          <!-- <div>
            <el-row>
              <el-col :span="12"><h3 style="margin: 5px">附件列表</h3></el-col>
              <el-col :span="12" align="right"> </el-col>
            </el-row>
            <el-table :data="form.fileAttaches" style="margin: 5px">
              <el-table-column label="文件名"></el-table-column>
              <el-table-column label="上传日期"></el-table-column>
            </el-table>
          </div> -->
          <div v-if="action != 'review'">
            <el-row>
              <el-col :span="12">评价记录</el-col>
              <el-col :span="12" align="right"> </el-col>
            </el-row>
            <el-table :data="form.performanceScoreReview &&
              form.performanceScoreReview.filter((p) => p.reviewDate != null)
              ">
              <el-table-column label="评价日期" prop="reviewDate">
              </el-table-column>
              <el-table-column label="评价人" prop="reviewUser"></el-table-column>
              <el-table-column label="评价结果" prop="result"></el-table-column>
              <el-table-column label="明细">
                <template #default="{ row: item }">
                  <el-button type="text" icon="el-icon-document" @click="showScoreDetail(item)"></el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="绩效评价" name="second" v-if="action == 'review'">
        <el-form :model="appraise" :rules="performanceScoreRule" label-width="0" ref="refForm">
          <table cellpadding="0" cellspacing="0" class="borderTable">
            <!-- <tr>
              <td class="bold" style="width: 15%">评价内容</td>
              <td class="bold" style="width: 50%">评价要点</td>
              <td class="bold" style="width: 35%">评价意见</td>
            </tr>
            <tr>
              <td colspan="3" class="bold left">一、完整性审核</td>
            </tr>
            <tr>
              <td style="width: 15%">规范完整性</td>
              <td class="left" style="width: 50%">
                1.绩效目标填表格式是否规范；<br />
                2.绩效目标填报内容是否完整、准确、详实，是否无缺项、错项。
              </td>
              <td style="width: 35%">
                <el-radio-group v-model="appraise.rule">
                  <el-radio-button v-for="item in resultDic" :label="item" :key="item">{{ item }}</el-radio-button>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td style="width: 15%">资金匹配性</td>
              <td class="left" style="width: 50%">
                1.绩效目标是否明确、清晰，是否能够反映专项或项目的主要内容；<br />
                2.是否对专项或项目的逾期产出和效果经行了充分、恰当的描述。
              </td>
              <td style="width: 35%">
                <el-radio-group v-model="appraise.fund">
                  <el-radio-button v-for="item in resultDic" :label="item" :key="item">{{ item }}</el-radio-button>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="3" class="bold left">二、相关性审核</td>
            </tr>
            <tr>
              <td style="width: 15%">目标相关性</td>
              <td class="left" style="width: 50%">
                1.总体目标是否符合国家法律法规、国民经济和社会发展规划要求；<br />
                2.总体目标是否符合中央和地方事权与只出责任划分规定；<br />
                3.总体目标是否符合专项的特定政策目标、用途、使用范围和预算支出内容等，是否符合财政部门和主管部门的要求。
              </td>
              <td style="width: 35%">
                <el-radio-group v-model="appraise.aim">
                  <el-radio-button v-for="item in resultDic" :label="item" :key="item">{{ item }}</el-radio-button>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td style="width: 15%">指标科学性</td>
              <td class="left" style="width: 50%">
                1.绩效指标是否全面、充分、细化、量化、难以量化的。定性描述是否充分、具体；<br />
                2.是否选取了最能体现总体目标实现程度的关键指标并明确了具体指标值。
              </td>
              <td style="width: 35%">
                <el-radio-group v-model="appraise.science">
                  <el-radio-button v-for="item in resultDic" :label="item" :key="item">{{ item }}</el-radio-button>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="3" class="bold left">三、适当性审核</td>
            </tr>
            <tr>
              <td style="width: 15%">绩效合理性</td>
              <td class="left" style="width: 50%">
                1.预期绩效是否显著，是否能够体现实际产出和效果的明显改善；<br />
                2.是否符合行业正常水平或事业发展规律；<br />
                3.是否没有出现与其他同类专项或项目绩效目标接近或雷同的情形；<br />
                4.逾期绩效是否合理，是否尚未实现或取消。
              </td>
              <td style="width: 35%">
                <el-radio-group v-model="appraise.performance">
                  <el-radio-button v-for="item in resultDic" :label="item" :key="item">{{ item }}</el-radio-button>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td style="width: 15%">资金匹配性</td>
              <td class="left" style="width: 50%">
                1.绩效目标与专项或项目资金规模、使用方向等是否匹配，在既定资金规模下，绩效目标是否过高或过低，或要完成既定绩效目标，资金规模是否过大或过小；<br />
                2.资金测算是否符合有关补助标准或比例规定，是否体现资金统筹使用和优先保障重点支出等要求。
              </td>
              <td style="width: 35%">
                <el-radio-group v-model="appraise.fundMatch">
                  <el-radio-button v-for="item in resultDic" :label="item" :key="item">{{ item }}</el-radio-button>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="3" class="bold left">四、可行性审核</td>
            </tr>
            <tr>
              <td style="width: 15%">实现可能性</td>
              <td class="left" style="width: 50%">
                1.绩效目标是否经过充分调查研究、论证、和合理测算；<br />
                2.绩效目标实现的可能性是否充分。
              </td>
              <td style="width: 35%">
                <el-radio-group v-model="appraise.done">
                  <el-radio-button v-for="item in resultDic" :label="item" :key="item">{{ item }}</el-radio-button>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <td style="width: 15%">条件充分性</td>
              <td class="left" style="width: 50%">
                1.专项或项目的实施方案、具体措施等是否合理；<br />
                2.主管部门或实施单位的组织实施能力和条件是否充分，内部控制是否规范，管理制度是否健全。
              </td>
              <td style="width: 35%">
                <el-radio-group v-model="appraise.condition">
                  <el-radio-button v-for="item in resultDic" :label="item" :key="item">{{ item }}</el-radio-button>
                </el-radio-group>
              </td>
            </tr> -->
            <tr>
              <td class="bold" style="width: 15%">
                <span style="color: red">*</span> 综合评定等级
              </td>
              <td colspan="2" style="width: 75%">
                <el-form-item label-width="0" prop="result" style="margin-bottom: 0px; margin-top: 20px">
                  <el-radio-group v-model="appraise.result">
                    <el-radio-button v-for="item in resultDic" :label="item" :key="item">{{ item }}</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                -
              </td>
            </tr>
            <tr>
              <td class="bold" style="width: 15%">总体意见</td>
              <td colspan="2" style="width: 85%">
                <el-input v-model="appraise.comment" type="textarea" rows="5"></el-input>
              </td>
            </tr>
          </table>
        </el-form>
      </el-tab-pane>
    </el-tabs>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visible = false">关闭</el-button>
        <template v-if="action != 'review'">
          <el-button type="primary" @click="exportPerformanceScoreExcel(id)">导出</el-button>
        </template>
        <template v-else-if="activeName == 'second'">
          <el-button type="success" @click="doSubmit(false)" :loading="isLoading">保存</el-button>
          <el-popconfirm title="确认提交吗！？" @confirm="doSubmit(true)" placement="top">
            <template #reference>
              <el-button type="primary" :loading="isLoading">提交</el-button>
            </template>
          </el-popconfirm>
        </template>
      </span>
    </template>
    <PerformanceScoreViewForm :setting="viewFormSetting" :appraise="viewAppraise"></PerformanceScoreViewForm>
  </el-dialog>
</template>

<script lang="ts">
import { performanceScoreRule } from "@/model/fundFormRules";
import PerformanceScoreViewForm from "./performanceScoreViewForm";
import {
  onMounted,
  defineComponent,
  toRefs,
  ref,
  Ref,
  reactive,
  watch,
  inject,
  nextTick
} from "vue";
import {
  usePerformanceScore,
  exportPerformanceScoreExcel,
} from "@/network/projectFundApply.ts";
import { copyFrom } from "@/network/common";
export default defineComponent({
  components: {
    PerformanceScoreViewForm,
  },
  props: {
    setting: { type: Object },
  },
  setup(props, { emit }) {
    const refForm = ref(null);
    const user: any = inject("user");
    const activeName = ref("first");

    const viewFormSetting = reactive({ visible: false });
    const { visible, id, action } = toRefs(props.setting as any);
    const [isLoading, form, find, save, submit] = usePerformanceScore(id);

    const resultDic = ["优", "良", "中", "差"];
    const empty = {
      rule: "",
      fund: "",
      aim: "",
      science: "",
      performance: "",
      fundMatch: "",
      done: "",
      condition: "",
      result: "",
      comment: "",
    };
    const appraise = reactive(copyFrom(empty));
    const viewAppraise = ref({});
    // watch(visible, (v) => {
    //   if (v) {
    //     find().then((res) => {
    //       if (action.value == "review" && res.performanceScoreReview) {
    //         const _appraise = res.performanceScoreReview.find(
    //           (p) => p.reviewUser == user.name && !p.reviewDate
    //         );
    //         copyFrom(appraise, empty, _appraise);
    //       } else {
    //         copyFrom(appraise, empty);
    //       }
    //       setTimeout(() => refForm.value.clearValidate(), 1);
    //     });
    //   }
    // });

    // watch(visible, (v) => {
    //   if (!visible.value) {
    //     activeName.value = "first";
    //   }
    // });

    onMounted(() => {
      activeName.value = "first";
      find().then((res) => {
        if (action.value == "review" && res.performanceScoreReview) {
          const _appraise = res.performanceScoreReview.find(
            (p) => p.reviewUser == user.name && !p.reviewDate
          );
          copyFrom(appraise, empty, _appraise);
        } else {
          copyFrom(appraise, empty);
        }
        nextTick(() => {
          setTimeout(() => refForm.value.clearValidate(), 1);
        })

      });

    })

    const showScoreDetail = (item) => {
      viewAppraise.value = item;
      viewFormSetting.visible = true;
    };

    const doSubmit = (saveFlag) => {
      return new Promise((resolve, reject) => {
        refForm.value.validate((valid) => {
          if (valid) {
            submit(id.value, saveFlag, appraise).then((d) => {
              if (saveFlag) {
                emit("confirm", { id: id.value });
                visible.value = false;
              }
              resolve("success");
            });
          } else {
            reject("error");
          }
        });
      });
    };
    return {
      id,
      refForm,
      showScoreDetail,
      exportPerformanceScoreExcel,
      viewFormSetting,
      doSubmit,
      appraise,
      viewAppraise,
      save,
      action,
      resultDic,
      activeName,
      isLoading,
      visible,
      form,
      performanceScoreRule,
    };
  },
});
</script>

<style  >
#performanceForm input {
  height: 20px;
  border: none;
  border-bottom: 1px dashed grey;
  background: white;
}
</style>