
import PerformanceViewForm from "./performanceViewForm";
import { defineComponent, ref, reactive } from "vue";
export default defineComponent({
  components: {
    PerformanceViewForm,
  },
  props: ["data", "project"],
  setup(props) {
    const currentSelectId = ref(null);
    const performaceViewFormSetting = reactive({
      id: currentSelectId,
      visible: false,
      project_Name: "",
      action: "",
    });
    const show = (item) => {
      currentSelectId.value = item.id;
      performaceViewFormSetting.visible = true;
      performaceViewFormSetting.project_Name = props.project.project_Name;
    };
    return { currentSelectId, performaceViewFormSetting, show };
  },
});
