
import { defineComponent, reactive, ref, toRefs, onMounted } from "vue";
import { numberRules } from "@/model/fundFormRules";
export default defineComponent({
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { data: listData } = toRefs<{ data: any }>(props);
    //const listData = reactive<Array<any>>(props.data as Array<any>);
    const empty = {
      item_Text: null,
      total_Fund: 0,
      central_Fund: 0,
    };
    const formData = reactive({});

    const reset = () => {
      Object.assign(formData, empty);
      //console.log(formData)
    };

    const add = () => {
      show.value = false;
      listData.value.push(Object.assign({}, formData));
    };
    const remove = (item) => {
      const index = listData.value.findIndex((p) => p == item);
      listData.value.splice(index, 1);
    };
    const show = ref(false);

    onMounted(() => {
      reset();
    })
    return {
      numberRules,
      listData,
      show,
      add,
      remove,
      formData,
      handleClose() {
        reset();
      },
    };
  },
});
