<template>
  <div class="form-panel">
    <el-table :data="listData">
      <el-table-column label="请示文件">
        <template #default="{ row: item }">
          <a :href="item.file_Path" :download="item.file_Name">{{
            item.file_Name
          }}</a>
        </template>
      </el-table-column>
      <el-table-column label="上传人" prop="update_User_Name"></el-table-column>
      <el-table-column label="上传时间">
        <template #default="{ row: item }">
          {{ item.update_Date }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";

export default defineComponent({
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const listData: Array<any> = reactive(props.data as any);
    return {
      listData,
    };
  },
});
</script>

<style scoped>
</style>