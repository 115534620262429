
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: ["data"],
  setup(props) {
    //console.log(props.data);
    return {};
  },
});
