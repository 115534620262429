
import { defineComponent, ref, reactive } from "vue";
import FundAmountReviewFormView from "./fundAmountReviewFormView.vue";
export default defineComponent({
  components: {
    FundAmountReviewFormView,
  },
  props: ["data", "project"],
  setup() {
    const fundReviewViewId = ref(null);
    const fundReviewViewSetting = reactive({
      id: fundReviewViewId,
      visible: false,
      project_Name: "",
      project_Code: "",
    });

    const showViewForm = (item) => {
      fundReviewViewSetting.visible = true;
      fundReviewViewSetting.project_Name = item.project_Name;
      fundReviewViewSetting.project_Code = item.project_Code;
      fundReviewViewId.value = item.id;
    };
    return { fundReviewViewSetting, fundReviewViewId, showViewForm };
  },
});
