<template>
  <div>
    <el-button
      v-show="edit"
      icon="el-icon-plus"
      type="primary"
      @click="show = true"
      >添加</el-button
    >
    <el-table :data="listData">
      <el-table-column label="年份" prop="year"></el-table-column>
      <el-table-column
        label="省级补助资金"
        prop="central_Fund"
      ></el-table-column>
      <el-table-column label="地方收入" prop="local_Fund"></el-table-column>
      <el-table-column label="自筹资金" prop="unit_Fund"></el-table-column>
      <el-table-column label="合计">
        <template #default="{ row: item }">
          {{ item.central_Fund + item.local_Fund + item.unit_Fund }}
        </template>
      </el-table-column>
      <el-table-column label="操作" v-if="edit">
        <template #default="{ row: item }">
          <el-popconfirm title="确定删除吗？" @confirm="remove(item)">
            <template #reference>
              <el-button type="danger" icon="el-icon-delete" circle></el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="添加用款计划"
      v-model="show"
      width="30%"
      @close="handleClose"
    >
      <el-form :model="formData">
        <el-form-item label="年份">
          <el-input v-model="formData.year"></el-input>
        </el-form-item>
        <el-form-item label="省级补助资金（万元）" :rules="numberRules" prop="central_Fund">
          <el-input v-model="formData.central_Fund"></el-input>
        </el-form-item>
        <el-form-item label="地方投入（万元）" :rules="numberRules" prop="local_Fund">
          <el-input v-model="formData.local_Fund"></el-input>
        </el-form-item>
        <el-form-item label="自筹资金（万元）" :rules="numberRules" prop="unit_Fund">
          <el-input v-model="formData.unit_Fund"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="show = false">取 消</el-button>
          <el-button type="primary" @click="add">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, toRefs, Ref, onMounted } from "vue";
import { numberRules } from "@/model/fundFormRules";
export default defineComponent({
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { data: listData } = toRefs<{ data: any }>(props);
    const empty = {
      year: null,
      central_Fund: 0,
      local_Fund: 0,
      unit_Fund: 0,
    };
    const formData = reactive<any>({});

    const reset = () => {
      Object.assign(formData, empty);
    };

    const add = () => {
      show.value = false;
      listData.value.push(Object.assign({}, formData));
    };
    const remove = (item) => {
      const index = listData.value.findIndex((p) => p == item);
      listData.value.splice(index, 1);
    };
    const show = ref(false);

    onMounted(()=>{
      reset();
    })
   
    return {
      numberRules,
      listData,
      show,
      add,
      remove,
      formData,
      handleClose() {
        reset();
      },
    };
  },
});
</script>

<style scoped>
</style>