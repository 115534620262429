<template>
  <el-dialog title="绩效评价明细" v-model="visible" width="70%">
    <table cellpadding="0" cellspacing="0" class="borderTable">
      <!-- <tr>
        <td class="bold" style="width: 15%">评价内容</td>
        <td class="bold" style="width: 50%">评价要点</td>
        <td class="bold" style="width: 35%">评价意见</td>
      </tr>
      <tr>
        <td colspan="3" class="bold left">一、完整性审核</td>
      </tr>
      <tr>
        <td style="width: 15%">规范完整性</td>
        <td class="left" style="width: 50%">
          1.绩效目标填表格式是否规范；<br />
          2.绩效目标填报内容是否完整、准确、详实，是否无缺项、错项。
        </td>
        <td style="width: 35%">
          {{ appraise.rule }}
        </td>
      </tr>
      <tr>
        <td style="width: 15%">资金匹配性</td>
        <td class="left" style="width: 50%">
          1.绩效目标是否明确、清晰，是否能够反映专项或项目的主要内容；<br />
          2.是否对专项或项目的逾期产出和效果经行了充分、恰当的描述。
        </td>
        <td style="width: 35%">
          {{ appraise.fund }}
        </td>
      </tr>
      <tr>
        <td colspan="3" class="bold left">二、相关性审核</td>
      </tr>
      <tr>
        <td style="width: 15%">目标相关性</td>
        <td class="left" style="width: 50%">
          1.总体目标是否符合国家法律法规、国民经济和社会发展规划要求；<br />
          2.总体目标是否符合中央和地方事权与只出责任划分规定；<br />
          3.总体目标是否符合专项的特定政策目标、用途、使用范围和预算支出内容等，是否符合财政部门和主管部门的要求。
        </td>
        <td style="width: 35%">
          {{ appraise.aim }}
        </td>
      </tr>
      <tr>
        <td style="width: 15%">指标科学性</td>
        <td class="left" style="width: 50%">
          1.绩效指标是否全面、充分、细化、量化、难以量化的。定性描述是否充分、具体；<br />
          2.是否选取了最能体现总体目标实现程度的关键指标并明确了具体指标值。
        </td>
        <td style="width: 35%">
          {{ appraise.science }}
        </td>
      </tr>
      <tr>
        <td colspan="3" class="bold left">三、适当性审核</td>
      </tr>
      <tr>
        <td style="width: 15%">绩效合理性</td>
        <td class="left" style="width: 50%">
          1.预期绩效是否显著，是否能够体现实际产出和效果的明显改善；<br />
          2.是否符合行业正常水平或事业发展规律；<br />
          3.是否没有出现与其他同类专项或项目绩效目标接近或雷同的情形；<br />
          4.逾期绩效是否合理，是否尚未实现或取消。
        </td>
        <td style="width: 35%">
          {{ appraise.performance }}
        </td>
      </tr>
      <tr>
        <td style="width: 15%">资金匹配性</td>
        <td class="left" style="width: 50%">
          1.绩效目标与专项或项目资金规模、使用方向等是否匹配，在既定资金规模下，绩效目标是否过高或过低，或要完成既定绩效目标，资金规模是否过大或过小；<br />
          2.资金测算是否符合有关补助标准或比例规定，是否体现资金统筹使用和优先保障重点支出等要求。
        </td>
        <td style="width: 35%">
          {{ appraise.fundMatch }}
        </td>
      </tr>
      <tr>
        <td colspan="3" class="bold left">四、可行性审核</td>
      </tr>
      <tr>
        <td style="width: 15%">实现可能性</td>
        <td class="left" style="width: 50%">
          1.绩效目标是否经过充分调查研究、论证、和合理测算；<br />
          2.绩效目标实现的可能性是否充分。
        </td>
        <td style="width: 35%">
          {{ appraise.done }}
        </td>
      </tr>
      <tr>
        <td style="width: 15%">条件充分性</td>
        <td class="left" style="width: 50%">
          1.专项或项目的实施方案、具体措施等是否合理；<br />
          2.主管部门或实施单位的组织实施能力和条件是否充分，内部控制是否规范，管理制度是否健全。
        </td>
        <td style="width: 35%">
          {{ appraise.condition }}
        </td>
      </tr> -->
      <tr>
        <td class="bold" style="width: 15%">综合评定等级</td>
        <td colspan="2" style="width: 75%">
          {{ appraise.result }}
        </td>
      </tr>
      <tr>
        <td class="bold" style="width: 15%">总体意见</td>
        <td colspan="2" style="width: 85%">
          {{ appraise.comment }}
        </td>
      </tr>
    </table>
    <template #footer>
      <span class="dialog-footer">
        <!-- <el-button type="primary">导出</el-button> -->
        <el-button @click="visible = false">关闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import { defineComponent, toRefs, watch } from "vue";

export default defineComponent({
  props: ["appraise", "setting"],
  setup(props) {
    const { visible } = toRefs(props.setting as any);
    return { visible };
  },
});
</script>

<style scoped>
</style>