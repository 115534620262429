<template>
  <div>
    <el-table :data="data">
      <el-table-column label="序号" type="index" align="center" header-align="center" width="80"></el-table-column>
      <el-table-column label="时间" prop="create_Date" align="center" header-align="center"></el-table-column>
      <el-table-column label="操作人" prop="create_UserName" align="center" header-align="center"></el-table-column>
      <el-table-column label="动作" prop="action_Name" align="center" header-align="center"></el-table-column>
      <el-table-column label="状态变动" prop="statusChange" align="center" header-align="center">
        <template #default="{ row: item }">
          {{ item.fromStatusName != null && item.toStatusName != null ? (item.fromStatus != item.toStatus ?
            `${item.fromStatusName} => ${item.toStatusName}` : item.fromStatusName) : item.fromStatusName ||
            item.toStatusName || ""
          }}
        </template>
      </el-table-column>
      <el-table-column label="备注" prop="memo"></el-table-column>
    </el-table>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: ["data"],
  setup(props) {
    //console.log(props.data);
    return {};
  },
});
</script>

<style scoped></style>