<template>
  <div>
    <el-table :data="data">
      <el-table-column label="接收时间" prop="recvDate"></el-table-column>
      <el-table-column label="评审机构" prop="orgName"></el-table-column>
      <el-table-column label="评审状态">
        <template #default="{ row: item }">
          {{ item.status == 1 ? "评审中" : "评审结束" }}
        </template>
      </el-table-column>
      <el-table-column
        label="评审金额(万元)"
        prop="assessment_Money"
      ></el-table-column>
      <el-table-column label="评审时间" prop="reviewDate"></el-table-column>
      <el-table-column label="评审单">
        <template #default="{ row: item }">
          <el-button
            type="text"
            icon="el-icon-document"
            @click="showViewForm(item)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <FundAmountReviewFormView
      :setting="fundReviewViewSetting"
      v-if="fundReviewViewSetting.visible"
    ></FundAmountReviewFormView>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive } from "vue";
import FundAmountReviewFormView from "./fundAmountReviewFormView.vue";
export default defineComponent({
  components: {
    FundAmountReviewFormView,
  },
  props: ["data", "project"],
  setup() {
    const fundReviewViewId = ref(null);
    const fundReviewViewSetting = reactive({
      id: fundReviewViewId,
      visible: false,
      project_Name: "",
      project_Code: "",
    });

    const showViewForm = (item) => {
      fundReviewViewSetting.visible = true;
      fundReviewViewSetting.project_Name = item.project_Name;
      fundReviewViewSetting.project_Code = item.project_Code;
      fundReviewViewId.value = item.id;
    };
    return { fundReviewViewSetting, fundReviewViewId, showViewForm };
  },
});
</script>

<style scoped>
</style>