
import { performanceScoreRule } from "@/model/fundFormRules";
import PerformanceScoreViewForm from "./performanceScoreViewForm";
import {
  onMounted,
  defineComponent,
  toRefs,
  ref,
  Ref,
  reactive,
  watch,
  inject,
  nextTick
} from "vue";
import {
  usePerformanceScore,
  exportPerformanceScoreExcel,
} from "@/network/projectFundApply.ts";
import { copyFrom } from "@/network/common";
export default defineComponent({
  components: {
    PerformanceScoreViewForm,
  },
  props: {
    setting: { type: Object },
  },
  setup(props, { emit }) {
    const refForm = ref(null);
    const user: any = inject("user");
    const activeName = ref("first");

    const viewFormSetting = reactive({ visible: false });
    const { visible, id, action } = toRefs(props.setting as any);
    const [isLoading, form, find, save, submit] = usePerformanceScore(id);

    const resultDic = ["优", "良", "中", "差"];
    const empty = {
      rule: "",
      fund: "",
      aim: "",
      science: "",
      performance: "",
      fundMatch: "",
      done: "",
      condition: "",
      result: "",
      comment: "",
    };
    const appraise = reactive(copyFrom(empty));
    const viewAppraise = ref({});
    // watch(visible, (v) => {
    //   if (v) {
    //     find().then((res) => {
    //       if (action.value == "review" && res.performanceScoreReview) {
    //         const _appraise = res.performanceScoreReview.find(
    //           (p) => p.reviewUser == user.name && !p.reviewDate
    //         );
    //         copyFrom(appraise, empty, _appraise);
    //       } else {
    //         copyFrom(appraise, empty);
    //       }
    //       setTimeout(() => refForm.value.clearValidate(), 1);
    //     });
    //   }
    // });

    // watch(visible, (v) => {
    //   if (!visible.value) {
    //     activeName.value = "first";
    //   }
    // });

    onMounted(() => {
      activeName.value = "first";
      find().then((res) => {
        if (action.value == "review" && res.performanceScoreReview) {
          const _appraise = res.performanceScoreReview.find(
            (p) => p.reviewUser == user.name && !p.reviewDate
          );
          copyFrom(appraise, empty, _appraise);
        } else {
          copyFrom(appraise, empty);
        }
        nextTick(() => {
          setTimeout(() => refForm.value.clearValidate(), 1);
        })

      });

    })

    const showScoreDetail = (item) => {
      viewAppraise.value = item;
      viewFormSetting.visible = true;
    };

    const doSubmit = (saveFlag) => {
      return new Promise((resolve, reject) => {
        refForm.value.validate((valid) => {
          if (valid) {
            submit(id.value, saveFlag, appraise).then((d) => {
              if (saveFlag) {
                emit("confirm", { id: id.value });
                visible.value = false;
              }
              resolve("success");
            });
          } else {
            reject("error");
          }
        });
      });
    };
    return {
      id,
      refForm,
      showScoreDetail,
      exportPerformanceScoreExcel,
      viewFormSetting,
      doSubmit,
      appraise,
      viewAppraise,
      save,
      action,
      resultDic,
      activeName,
      isLoading,
      visible,
      form,
      performanceScoreRule,
    };
  },
});
