
import { defineComponent, toRefs, watch } from "vue";

export default defineComponent({
  props: ["appraise", "setting"],
  setup(props) {
    const { visible } = toRefs(props.setting as any);
    return { visible };
  },
});
