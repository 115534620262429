
import LogHistory from "./logHistory.vue";
import AnnualBudgetTable from "./annual_budgetTable.vue";
import BudgetContentTable from "./budget_contentTable.vue";
import ReviewLogTable from "./reviewLogTable";
import PerformanceTable from "./performanceTable";
import BudgetApplyTable from "./budgetApplyTable";

// import BudgetAdjustLogTable from "./budgetAdjustLogTable";
// import YearReportTable from "./yearReportTable";

import { reactiveShowSetting } from "@/types/showSetting.ts";
import { DocComponents } from "@/network/officialDoc.ts";
import {
  useFindProjectFundApplyView,
  useProjectFundApplyToDocs,
  useQueryActionHistory,
  useRunProjectFundApply,
  exportFundProjectWord,
} from "@/network/projectFundApply.ts";
import {
  Ref,
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRef,
  toRefs,
  watch,
  PropType,
  onUpdated,
} from "vue";
import ReViewPanel from "@/components/reviewPanel.vue";
import ProjectAttachList from "@/views/shared/projectAttachList.vue";
import RequestDocList from "@/views/shared/requestDocList.vue";

//components
export default defineComponent({
  name: "projectCreate",
  components: {
    LogHistory,
    ProjectAttachList,
    RequestDocList,
    ReViewPanel,
    ...DocComponents,
    ReviewLogTable,
    PerformanceTable,
    BudgetApplyTable,
    // YearReportTable,
    // BudgetAdjustLogTable,
    AnnualBudgetTable,
    BudgetContentTable,
  },
  props: {
    setting: {
      type: Object as PropType<{
        id: number;
        visible: boolean;
        review: boolean;
      }>,
    },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const activeName = ref(null);
    const firstCollapseActiveName = ref(null);
    const secondCollapseActiveName = ref(null);
    const thirdCollapseActiveName = ref(null);

    const { id, visible, review } = toRefs(props.setting as any);
    const [actionHistory, queryHistory] = useQueryActionHistory(id);
    const [loading, form, find, cancel]: [Ref<boolean>, any, any, any] =
      useFindProjectFundApplyView(id);
    const [setupDoc, proposalDoc] = useProjectFundApplyToDocs(form);

    const close = () => {
      if (cancel.value != null && loading.value) {
        cancel.value.cancel("取消加载...");
      }
    };

    const reload = () => {
      if (id.value == 0) return;
      queryHistory();
      find();
      activeName.value = "first";
      firstCollapseActiveName.value = ["1", "2", "3", "4", "5", "6"];
      secondCollapseActiveName.value = ["1", "2", "3"];
      thirdCollapseActiveName.value = ["1", "2"];
    };
    //reload();
    // watch(visible, () => {
    //   if (visible) {
    //     reload();
    //   }
    // });

    onMounted(()=>{
      reload();
    })
    const docShowSetting = reactive({
      id: null,
      visible: false,
      approve: false,
    });

    const showDocContent = (id) => {
      docShowSetting.id = id;
      docShowSetting.visible = true;
    };

    const [isProcessing, run] = useRunProjectFundApply(() => {
      visible.value = false;
      emit("change");
      //console.log("111");
    });

    const saveReview = (result) => {
      run(id.value, Object.assign({ forceFlag: 4 }, result));
    };
    const title = computed(() => {
      if (loading.value) {
        return "数据加载中...";
      } else {
        return `[${form.value.project_Code}]` + form.value.project_Name;
      }
    });

    return {
      id,
      isProcessing,
      showDocContent,
      actionHistory,
      review,
      saveReview,
      close,
      firstCollapseActiveName,
      secondCollapseActiveName,
      thirdCollapseActiveName,
      activeName,
      visible,
      loading,
      docShowSetting,
      setupDoc,
      proposalDoc,
      title,
      form,
      exportFundProjectWord,
    };
  },
});
