<template>
  <div>
    <el-table :data="data">
      <!-- <el-table-column label="资金类别"></el-table-column> -->
      <el-table-column label="资金年度" prop="applyYear"></el-table-column>
      <!-- <el-table-column label="批次" prop="applyBatch"></el-table-column> -->
      <el-table-column label="申请日期" prop="applyCreateDate"></el-table-column>
      <el-table-column label="申请金额（万元）" prop="applyAmount"></el-table-column>
      <el-table-column label="状态" prop="applyStatusName"></el-table-column>
      <el-table-column label="签赔金额（万元）" prop="allocateAmount"></el-table-column>
      <el-table-column label="备注" prop="applyRemark"></el-table-column>
    </el-table>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: ["data"],
  setup() {
    return {};
  },
});
</script>

<style scoped>
</style>