
<template>
  <div>
    <el-button icon="el-icon-plus" v-show="edit" type="primary" @click="show = true">添加</el-button>
    <el-table :data="listData">
      <el-table-column label="预算构成" prop="item_Text"></el-table-column>
      <el-table-column label="金额（万元）" prop="total_Fund"></el-table-column>
      <el-table-column label="申请省级补助（万元）" prop="central_Fund"></el-table-column>

      <el-table-column label="操作" v-if="edit">
        <template #default="{ row: item }">
          <el-popconfirm title="确定删除吗？" @confirm="remove(item)">
            <template #reference>
              <el-button type="danger" icon="el-icon-delete" circle></el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="添加预算构成" v-model="show" width="30%" @close="handleClose">
      <el-form :model="formData">
        <el-form-item label="预算构成">
          <el-input v-model="formData.item_Text"></el-input>
        </el-form-item>
        <el-form-item label="金额" :rules="numberRules" prop="total_Fund">
          <el-input v-model="formData.total_Fund"></el-input>
        </el-form-item>
        <el-form-item label="省级补助资金（万元）" :rules="numberRules" prop="central_Fund">
          <el-input v-model="formData.central_Fund"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="show = false">取 消</el-button>
          <el-button type="primary" @click="add">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, toRefs, onMounted } from "vue";
import { numberRules } from "@/model/fundFormRules";
export default defineComponent({
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { data: listData } = toRefs<{ data: any }>(props);
    //const listData = reactive<Array<any>>(props.data as Array<any>);
    const empty = {
      item_Text: null,
      total_Fund: 0,
      central_Fund: 0,
    };
    const formData = reactive({});

    const reset = () => {
      Object.assign(formData, empty);
      //console.log(formData)
    };

    const add = () => {
      show.value = false;
      listData.value.push(Object.assign({}, formData));
    };
    const remove = (item) => {
      const index = listData.value.findIndex((p) => p == item);
      listData.value.splice(index, 1);
    };
    const show = ref(false);

    onMounted(() => {
      reset();
    })
    return {
      numberRules,
      listData,
      show,
      add,
      remove,
      formData,
      handleClose() {
        reset();
      },
    };
  },
});
</script>

<style scoped></style>