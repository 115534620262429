<template>
  <div>
    <el-table :data="data">
      <el-table-column
        label="资金年度"
        prop="fundYearIndex"
      ></el-table-column>
<!-- 
      <el-table-column
        label="批次"
        prop="fundYearBatch"
      ></el-table-column> -->
      <el-table-column label="填报日期" prop="createDate"></el-table-column>
      <el-table-column label="填报状态" prop="status">
        <template #default="{ row: item }">
          {{
            item.status == 0 ? "填报中" : item.status == 1 ? "评价中" : "已评价"
          }}
        </template>
      </el-table-column>
      <el-table-column label="明细">
        <template #default="{ row: item }">
          <el-button type="text" icon="el-icon-document" @click="show(item)">
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <PerformanceViewForm
      :setting="performaceViewFormSetting"
      v-if="performaceViewFormSetting.visible"
    ></PerformanceViewForm>
  </div>
</template>

<script lang="ts">
import PerformanceViewForm from "./performanceViewForm";
import { defineComponent, ref, reactive } from "vue";
export default defineComponent({
  components: {
    PerformanceViewForm,
  },
  props: ["data", "project"],
  setup(props) {
    const currentSelectId = ref(null);
    const performaceViewFormSetting = reactive({
      id: currentSelectId,
      visible: false,
      project_Name: "",
      action: "",
    });
    const show = (item) => {
      currentSelectId.value = item.id;
      performaceViewFormSetting.visible = true;
      performaceViewFormSetting.project_Name = props.project.project_Name;
    };
    return { currentSelectId, performaceViewFormSetting, show };
  },
});
</script>

<style scoped></style>
