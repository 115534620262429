import request from "@/utils/request";
import axios from "axios";
import { ref, Ref, reactive, onMounted, toRef, watch, isReactive } from "vue";
import { ElMessage } from "element-plus";
import { copyFrom } from "./common";
import { openWindowWithToken } from "@/utils/util";

export function useQueryCountProjectFundApply(): Array<Ref<boolean> | any> {
  const result = reactive({
    draft: 0,
    cityReview: 0,
    todo: 0,
    doing: 0,
    alternativeLib: 0,
    fundApply: 0,
    doProject: 0,
    giveFund: 0,
    end: 0,
    all: 0,
  });

  const query = () => {
    request.get(`/projectFundApply/querycount`).then((p) => {
      Object.assign(result, p);
    });
  };
  return [result, query];
}

export function useProjectFundApplyToDocs(data) {
  const setupDoc = reactive({ id: 0, doc_Title: "", doc_Compile_No: "" });
  const proposalDoc = reactive({ id: 0, doc_Title: "", doc_Compile_No: "" });

  const setData = (d) => {
    setupDoc.id = d.setupDoc_Id;
    setupDoc.doc_Title = d.setupDoc_Title;
    setupDoc.doc_Compile_No = d.setupDoc_Compile_No;

    proposalDoc.id = d.proposalDoc_Id;
    proposalDoc.doc_Title = d.proposalDoc_Title;
    proposalDoc.doc_Compile_No = d.proposalDoc_Compile_No;
  };
  if (!isReactive(data)) {
    watch(
      () => data.value.id,
      () => {
        setData(data.value);
      }
    );
  } else {
    watch(
      () => data.id,
      () => {
        setData(data);
      }
    );
  }

  return [setupDoc, proposalDoc];
}

export function useProjectFundApply(id: Ref<number>): any {
  const emptyFundApply = {
    id: 0,
    planDesignUnit: null,
    project_Code: null,
    hasProjectPlan: false,
    projectPlan_Id: null,
    needProposalDoc: true,
    project_Year: null,
    noDoc_Note: "",
    setupDoc_Id: null,
    proposalDoc_Id: null,
    heritageUnit_Id: null,
    heritageUnit: {},
    project_Classify_Id: null,
    project_SubClassify_Id: null,
    project_Name: "",
    budget_Total: 0,
    province_Apply_Count: 0,
    country_Apply_Count: 0,
    other_Apply_Count: 0,
    execute_Unit_Name: "",
    fund_Manage_Unit: "",
    implement_Unit: "",
    project_Compile_Unit_Name: "",
    project_Manager: "",
    manager_Cell_Phone: "",
    superior_Name: "",
    superior_Contact: "",
    unit_Address: "",
    compile_Unit_Name: "",
    unit_Brief_Introduce: "",
    brief_Description: "",
    past_Protect_Description: "",
    project_Content: "",
    annual_Execute_Plan: "",
    comments: "",
    local_Fund_Usage_Description: "",
    budget_ContentList: [],
    annual_BudgetList: [],
    projectAttachList: [
      {
        attach_Item_Id: 1,
        projectAttachItem: {
          id: 1,
          attach_Item_Name: "年度计划文本",
          attach_Exts: ".Doc,.Docx,.Pdf",
          allowNull: true,
        },
        sequence: 1,
        fileAttach: {
          file_Name: null,
          file_Path: null,
        },
      },
      {
        attach_Item_Id: 3,
        projectAttachItem: {
          id: 3,
          attach_Item_Name: "预算文本",
          attach_Exts: ".Doc,.Docx,.Xls,.Xlsx",
          allowNull: false,
        },
        sequence: 2,
        fileAttach: {
          file_Name: null,
          file_Path: null,
        },
      },
      {
        attach_Item_Id: 2,
        projectAttachItem: {
          id: 2,
          attach_Item_Name: "方案文本",
          attach_Exts: ".Pdf,.Zip",
          allowNull: false,
        },
        sequence: 3,
        fileAttach: {
          file_Name: null,
          file_Path: null,
        },
      },
      {
        attach_Item_Id: 45,
        projectAttachItem: {
          id: 45,
          attach_Item_Name: "预算（软件版）",
          attach_Exts: ".Pdf",
          allowNull: true,
        },
        sequence: 4,
        fileAttach: {
          file_Name: null,
          file_Path: null,
        },
      },
    ],
    requestDocList: [
      {
        request_type: 1,
        request_typeName: "申报单位请示文件",
        required: true,

        fileAttach: {
          file_Name: null,
          file_Path: null,
        },
      },
    ],
    status: 1,
  };

  const loading = ref(false);
  const processing = ref(false);
  const result = reactive(copyFrom(emptyFundApply));
  const cancel = ref(null);
  function find() {
    if (id.value != 0) {
      const cancelTokenSource = axios.CancelToken.source();
      cancel.value = cancelTokenSource;
      loading.value = true;
      return request
        .get(`/projectFundApply/${id.value}`, {
          cancelToken: cancelTokenSource.token,
        })
        .then((res) => {
          copyFrom(result, emptyFundApply, res);
          if (
            result.projectAttachList == null ||
            result.projectAttachList.length == 0
          ) {
            result.projectAttachList = copyFrom(
              emptyFundApply.projectAttachList
            );
          }

          if (
            result.requestDocList == null ||
            result.requestDocList.length == 0
          )
            result.requestDocList = copyFrom(emptyFundApply.requestDocList);
          loading.value = false;
        });
    } else {
      loading.value = false;
      copyFrom(result, emptyFundApply);
      return Promise.resolve(result);
    }
  }
  function save() {
    processing.value = true;
    let ps = null;
    if (id.value != 0) {
      ps = request
        .put(`/projectFundApply/${id.value}`, result)
        .then((res) => {
          copyFrom(result, emptyFundApply, res);

          processing.value = false;
          ElMessage.success({ message: "数据已保存", type: "success" });
        })
        .catch((e) => {
          processing.value = false;
          throw e;
        });
    } else {
      ps = request
        .post(`/projectFundApply`, result)
        .then((res) => {
          copyFrom(result, emptyFundApply, res);
          processing.value = false;
          ElMessage.success({ message: "数据已保存", type: "success" });
        })
        .catch((e) => {
          processing.value = false;
          throw e;
        });
    }

    return ps;
  }
  return { loading, result, find, cancel, save, processing };
}

export function useFindProjectFundApplyView(id: Ref<number>): any {
  const isLoading = ref(false);
  const result = ref({});
  const cancel = ref(null);
  function find() {
    if (id.value != 0) {
      const cancelTokenSource = axios.CancelToken.source();
      cancel.value = cancelTokenSource;
      isLoading.value = true;
      return request
        .get(`/projectFundApply/view/${id.value}`, {
          cancelToken: cancelTokenSource.token,
        })
        .then((res) => {
          result.value = res;
          isLoading.value = false;
        });
    }
  }
  return [isLoading, result, find, cancel];
}

export function createPerformanceScore(id) {
  return request.get(`/projectFundApply/createPerformanceScore/${id}`);
}
export function usePerformanceScore(id: Ref<number>): any {
  const empty = {
    id: 0,
    provDept: null,
    doDept: null,
    yearAmount: null,
    fiscalAmount: null,
    otherAmount: null,
    aim: null,
    performanceScoreItems: [],
  };
  const isLoading = ref(false);
  const result = reactive(copyFrom(empty));
  const cancel = ref(null);
  function find() {
    //console.log(id.value, "1111");
    if (id.value) {
      const cancelTokenSource = axios.CancelToken.source();
      cancel.value = cancelTokenSource;
      isLoading.value = true;

      return request
        .get(`/projectFundApply/findPerformanceScore/${id.value}`, {
          cancelToken: cancelTokenSource.token,
        })
        .then((res) => {
          copyFrom(result, empty, res);
          //console.log(result, res);
          isLoading.value = false;
          return res;
        });
    }
  }

  function save() {
    isLoading.value = true;
    let ps = null;
    if (id.value != 0) {
      const cancelTokenSource = axios.CancelToken.source();
      cancel.value = cancelTokenSource;
      ps = request
        .put(`/projectFundApply/savePerformanceScore/${id.value}`, result)
        .then((res) => {
          copyFrom(result, empty, res);

          isLoading.value = false;
          ElMessage.success({ message: "数据已保存", type: "success" });
        })
        .catch((e) => {
          isLoading.value = false;
          throw e;
        });
    }

    return ps;
  }

  //saveFlag true 提交 false保存
  function submit(idValue = null, saveFlag = false, review = null) {
    isLoading.value = true;
    let ps = null;
    const v = idValue == null ? id.value : idValue;
    if (v) {
      const cancelTokenSource = axios.CancelToken.source();
      cancel.value = cancelTokenSource;
      ps = request
        .put(
          `/projectFundApply/submitPerformanceScore/${v}?flag=${saveFlag}`,
          review ? review : { 1: 1 }
        )
        .then((res) => {
          copyFrom(result, empty, res);
          isLoading.value = false;
          ElMessage.success({
            message: `${saveFlag === true ? "保存" : "操作"}成功！`,
            type: "success",
          });
        })
        .catch((e) => {
          isLoading.value = false;
          throw e;
        });
    }
    return ps;
  }
  return [isLoading, result, find, save, submit, cancel];
}

export function useFundApply(id: Ref<number>): any {
  const empty = { id: 0, applyAmount: null, applyRemark: null };
  const isLoading = ref(false);
  const result = reactive(copyFrom(empty));
  const cancel = ref(null);
  function find() {
    if (id.value) {
      const cancelTokenSource = axios.CancelToken.source();
      cancel.value = cancelTokenSource;
      isLoading.value = true;

      return request
        .get(`/projectFundApply/findFundApply/${id.value}`, {
          cancelToken: cancelTokenSource.token,
        })
        .then((res) => {
          copyFrom(result, empty, res);

          isLoading.value = false;
          return res;
        });
    } else {
      isLoading.value = false;
      copyFrom(result, empty);
      return Promise.resolve(result);
    }
  }

  function submit() {
    isLoading.value = true;
    let ps = null;
    const cancelTokenSource = axios.CancelToken.source();
    cancel.value = cancelTokenSource;
    ps = id.value
      ? request.put(`/projectFundApply/submitFundApply/${id.value}`, result)
      : request.post(`/projectFundApply/submitFundApply`, result);
    ps.then((res) => {
      copyFrom(result, empty, res);

      isLoading.value = false;
      ElMessage.success({
        message: `操作成功！`,
        type: "success",
      });
    }).catch((e) => {
      isLoading.value = false;
      throw e;
    });
    return ps;
  }

  function back(_id) {
    isLoading.value = true;
    let ps = null;
    const cancelTokenSource = axios.CancelToken.source();
    cancel.value = cancelTokenSource;
    ps = request.put(`/projectFundApply/backFundApply/${_id}`);
    ps.then((res) => {
      isLoading.value = false;
      ElMessage.success({
        message: `操作成功！`,
        type: "success",
      });
    }).catch((e) => {
      isLoading.value = false;
      throw e;
    });
    return ps;
  }
  return [isLoading, result, find, submit, back, cancel];
}

export function useRunProjectFundApply(done = null): Array<Ref<boolean> | any> {
  const isProcessing: Ref<boolean> = ref(false);
  const action = (id, args) => {
    if (!Array.isArray(id)) {
      id = [id];
    }
    isProcessing.value = true;
    return request
      .put(`/projectFundApply/run`, Object.assign({ forceFlag: 0, id }, args))
      .then((res) => {
        isProcessing.value = false;
        ElMessage.success({
          message: "操作成功！",
          type: "success",
        });
        done && done();
        return res;
      })
      .catch(() => {
        isProcessing.value = false;
        ElMessage.error("操作失败！请刷新重试或联系管理员!");
      });
  };
  return [isProcessing, action];
}

export function useQueryLibProjectFundApply(): Array<any> {
  const result = reactive({
    list: [],
    count: 0,
  });
  const isLoading = ref(false);
  const filter = reactive({
    pageNum: 1,
    pageSize: 20,
    multipleStatus: [],
    keyword: null,
    isAlternativeLib: false,
  });
  const pager = reactive({
    count: toRef(result, "count"),
    pageSize: toRef(filter, "pageSize"),
    pageNum: toRef(filter, "pageNum"),
  });
  function query(replaceFilter = null) {
    isLoading.value = true;
    if (replaceFilter != null) Object.assign(filter, replaceFilter);
    request
      .post(`/projectFundApply/libquery`, filter)
      .then((res) => {
        isLoading.value = false;
        result.list = res.list;
        result.count = res.count;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  return [isLoading, result, query, filter, pager];
}

export function useQueryActionHistory(id, ctype = "FundApply"): Array<any> {
  const result = ref([]);
  function query() {
    request
      .get(`/projectFundApply/logHistory/${id.value}?ctype=${ctype}`)
      .then((res) => {
        result.value = res;
      });
  }

  return [result, query];
}

export function useAllowPerformanceScoreList() {
  const result = reactive({
    list: [],
    count: 0,
  });
  const isLoading = ref(false);
  const filter = reactive({
    pageNum: 1,
    pageSize: 20,
    keyword: null,
    city: null,
    town: null,
    project_Classify_Id: null,
    project_SubClassify_Id: null,
  });
  const pager = reactive({
    count: toRef(result, "count"),
    pageSize: toRef(filter, "pageSize"),
    pageNum: toRef(filter, "pageNum"),
  });
  function query(replaceFilter = null) {
    isLoading.value = true;
    if (replaceFilter != null) Object.assign(filter, replaceFilter);

    return request
      .get(`/projectFundApply/allowPerformanceScoreList`, { params: filter })
      .then((res) => {
        isLoading.value = false;
        result.list = res.list;
        result.count = res.count;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }
  return { isLoading, result, query, filter, pager };
}

export function useQueryProjectFundApply(initFilter = null): Array<any> {
  const result = reactive({
    list: [],
    count: 0,
  });
  const isLoading = ref(false);
  const filter = reactive({
    year: null,
    pageNum: 1,
    pageSize: 20,
    status: null,
    childStatus: null,
    reviewStatus: null,
    keyword: null,
    city: null,
    town: null,
    project_Classify_Id: null,
    project_SubClassify_Id: null,
    isFundCountry: false,
    fundApplyYear: null,
  });
  const pager = reactive({
    count: toRef(result, "count"),
    pageSize: toRef(filter, "pageSize"),
    pageNum: toRef(filter, "pageNum"),
  });
  function query(replaceFilter = null, isExport = false) {
    isLoading.value = true;
    if (replaceFilter != null) Object.assign(filter, replaceFilter);
    let url = "";

    if (
      filter.status == "performanceSubmit" ||
      filter.status == "performanceReview"
    ) {
      url = "queryPerformanceScore";
    } else if (filter.status == "fundApply") {
      url = "queryFundApply";
    } else if (filter.status == "fundApplyAllocate") {
      url = "queryFundApply";
      filter.childStatus = 3;
    } else {
      url = "query";
    }
    if (!isExport) {
      return request
        .get(`/projectFundApply/${url}`, { params: filter })
        .then((res) => {
          isLoading.value = false;
          result.list = res.list;
          result.count = res.count;
        })
        .catch(() => {
          isLoading.value = false;
        });
    } else {
      isLoading.value = false;
      openWindowWithToken(
        `${process.env.VUE_APP_BASE_API}/projectFundApply/export/${url}`,
        filter
      );
    }
  }

  return [isLoading, result, query, filter, pager];
}

export const exportFundProjectWord = (id) => {
  if (id)
    openWindowWithToken(
      `${process.env.VUE_APP_BASE_API}/projectFundApply/export/project/${id}`
    );
};

export const exportPerformanceScoreExcel = (id) => {
  if (id)
    openWindowWithToken(
      `${process.env.VUE_APP_BASE_API}/projectFundApply/export/performanceScore/${id}`
    );
};

export function useQueryAmountReview(initFilter = null): Array<any> {
  const result = reactive({
    list: [],
    count: 0,
  });
  const isLoading = ref(false);
  const filter = reactive({
    pageNum: 1,
    pageSize: 20,
  });
  const pager = reactive({
    count: toRef(result, "count"),
    pageSize: toRef(filter, "pageSize"),
    pageNum: toRef(filter, "pageNum"),
  });
  function query(replaceFilter = null) {
    isLoading.value = true;
    if (replaceFilter != null) Object.assign(filter, replaceFilter);

    request
      .get(`/projectFundApply/queryAmountReview`, { params: filter })
      .then((res) => {
        isLoading.value = false;
        result.list = res.list;
        result.count = res.count;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  return [isLoading, result, query, filter, pager];
}

export function useAmountReview(id: Ref<number>): any {
  const empty = {
    id: 0,
    submit_Money: null,
    assessment_Money: null,
    reduces: [],
    otherAdvise: null,
    expertAdvise: null,
    expertSignIn: null,
    expertSignInDate: null,
    checkSignIn: null,
    checkDate: null,
    attachment: null,
  };
  const isLoading = ref(false);
  const result = reactive(copyFrom(empty));
  const cancel = ref(null);
  function find() {
    if (id.value) {
      const cancelTokenSource = axios.CancelToken.source();
      cancel.value = cancelTokenSource;
      isLoading.value = true;

      return request
        .get(`/projectFundApply/findAmountReview/${id.value}`, {
          cancelToken: cancelTokenSource.token,
        })
        .then((res) => {
          copyFrom(result, empty, res);

          isLoading.value = false;
          return res;
        });
    } else {
      return Promise.resolve({});
    }
  }

  function save() {
    isLoading.value = true;
    let ps = null;
    if (id.value != 0) {
      const cancelTokenSource = axios.CancelToken.source();
      cancel.value = cancelTokenSource;
      ps = request
        .put(`/projectFundApply/saveAmountReview/${id.value}`, result)
        .then((res) => {
          copyFrom(result, empty, res);

          isLoading.value = false;
          ElMessage.success({ message: "数据已保存", type: "success" });
        })
        .catch((e) => {
          isLoading.value = false;
          throw e;
        });
    }

    return ps;
  }

  //saveFlag null 提交 true保存，false 退回
  function submit(idValue = null) {
    isLoading.value = true;
    let ps = null;
    if (id.value != 0) {
      const cancelTokenSource = axios.CancelToken.source();
      cancel.value = cancelTokenSource;
      ps = request
        .put(
          `/projectFundApply/submitAmountReview/${
            idValue == null ? id.value : idValue
          }`
        )
        .then((res) => {
          copyFrom(result, empty, res);

          isLoading.value = false;
          ElMessage.success({
            message: `操作成功！`,
            type: "success",
          });
        })
        .catch((e) => {
          isLoading.value = false;
          throw e;
        });
    }
    return ps;
  }
  return [isLoading, result, find, save, submit, cancel];
}

export function useAction(): any {
  const isLoading = ref(false);
  const cancel = ref(null);

  function backAmountReview(idValue) {
    isLoading.value = true;
    let ps = null;
    if (idValue != 0) {
      const cancelTokenSource = axios.CancelToken.source();
      cancel.value = cancelTokenSource;
      ps = request
        .put(`/projectFundApply/backAmountReview/${idValue}`)
        .then((res) => {
          isLoading.value = false;
          if (res) {
            ElMessage.success({
              message: `操作成功！`,
              type: "success",
            });
          }
          return res;
        })
        .catch((e) => {
          isLoading.value = false;
          throw e;
        });
    }
    return ps;
  }
  return [isLoading, backAmountReview, cancel];
}
